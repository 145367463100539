import paper, { Path, Point, Size } from 'paper'

export const undoAction = (event, redoList) => {
  const children = paper.project.activeLayer.children
  if (children.length > 0) {
    let index = 1
    let item = children[children.length - index]
    children[children.length - index].remove()
    redoList.push(item)
  }
}

export const redoAction = (event, redoList) => {
  if (redoList.length > 0) {
    paper.project.activeLayer.addChild(redoList.pop())
  }
}

export const toggleGrid = () => {
  paper.project.layers[(paper.project.layers.length - 1)].visible = !paper.project.layers[(paper.project.layers.length - 1)].visible
  return paper.project.layers[(paper.project.layers.length - 1)].visible
}

export const clearCanvas = (dimensions) => {
  paper.project.activeLayer.removeChildren()
  const rectSize = new Size(dimensions.sizePerRectangle, dimensions.sizePerRectangle)
  const invisPoint = new Point(dimensions.canvasWidth - dimensions.sizePerRectangle, dimensions.canvasHeight - dimensions.sizePerRectangle)
  const invis = new Path.Rectangle(invisPoint, rectSize)
  invis.fillColor = 'black'
  invis.blendMode = 'destination-out'
  invis.opacity = 0

  const zeroPoint = new Point(0, 0)
  const zero = new Path.Rectangle(zeroPoint, rectSize)
  zero.fillColor = 'black'
  zero.blendMode = 'destination-out'
  zero.opacity = 0
}
