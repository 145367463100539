import ColorThief from 'colorthief'
import '../../styles/app.css'

const colorThief = new ColorThief()

export const RGBToHex = (r, g, b) => {
  r = r.toString(16)
  g = g.toString(16)
  b = b.toString(16)

  if (r.length === 1) { r = '0' + r }
  if (g.length === 1) { g = '0' + g }
  if (b.length === 1) { b = '0' + b }

  return '#' + r + g + b
}

const RGBToHSL = (r, g, b) => {
  // Make r, g, and b fractions of 1
  r /= 255
  g /= 255
  b /= 255

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0
  // Calculate hue
  // No difference
  // eslint-disable-next-line brace-style
  if (delta === 0) { h = 0 }
  // Red is max
  // eslint-disable-next-line brace-style
  else if (cmax === r) { h = ((g - b) / delta) % 6 }
  // Green is max
  // eslint-disable-next-line brace-style
  else if (cmax === g) { h = (b - r) / delta + 2 }
  // Blue is max
  else { h = (r - g) / delta + 4 }

  h = Math.round(h * 60)

  // Make negative hues positive behind 360°
  if (h < 0) { h += 360 }
  // Calculate lightness
  l = (cmax + cmin) / 2

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return [h, s, l]
}

const balance = [1, 0, 1]
const colorDistance = (color1, color2) => {
  let result = 0
  color1 = RGBToHSL(color1[0], color1[1], color1[2])
  color2 = RGBToHSL(color2[0], color2[1], color2[2])
  for (let i = 0; i < color1.length; i++) { result += (color1[i] - color2[i]) * (color1[i] - color2[i]) * balance[i] }
  return result
}

const sortColors = (colors) => {
  // Calculate distance between each color
  const distances = []
  for (let i = 0; i < colors.length; i++) {
    distances[i] = []
    for (let j = 0; j < i; j++) { distances.push([colors[i], colors[j], colorDistance(colors[i], colors[j])]) }
  }
  distances.sort(function (a, b) {
    return a[2] - b[2]
  })

  // Put each color into separate cluster initially
  const colorToCluster = {}
  for (let i = 0; i < colors.length; i++) { colorToCluster[colors[i]] = [colors[i]] }

  // Merge clusters, starting with lowest distances
  let lastCluster
  for (let i = 0; i < distances.length; i++) {
    const color1 = distances[i][0]
    const color2 = distances[i][1]
    const cluster1 = colorToCluster[color1]
    const cluster2 = colorToCluster[color2]
    if (!cluster1 || !cluster2 || cluster1 === cluster2) { continue }

    // Make sure color1 is at the end of its cluster and
    // color2 at the beginning.
    if (color1 !== cluster1[cluster1.length - 1]) { cluster1.reverse() }
    if (color2 !== cluster2[0]) { cluster2.reverse() }

    // Merge cluster2 into cluster1
    cluster1.push.apply(cluster1, cluster2)
    delete colorToCluster[color1]
    delete colorToCluster[color2]
    colorToCluster[cluster1[0]] = cluster1
    colorToCluster[cluster1[cluster1.length - 1]] = cluster1
    lastCluster = cluster1
  }

  // By now all colors should be in one cluster
  return lastCluster
}

export const readImage = (e, getCustomColors, setCustomColors) => {
  const files = e.target.files
  if (!files || !files[0]) return
  // eslint-disable-next-line no-undef
  const FR = new FileReader()
  FR.addEventListener('load', (evt) => {
    // eslint-disable-next-line no-undef
    const img = new Image()
    img.addEventListener('load', () => {
      let colors = colorThief.getPalette(img, 20, 10)

      colors = sortColors(colors)

      const newColors = []
      for (let i = 0; i < colors.length; i++) {
        newColors.push(RGBToHex(colors[i][0], colors[i][1], colors[i][2]))
      }

      let customColors = getCustomColors()
      customColors = customColors.concat(newColors)
      setCustomColors(customColors)
    })
    img.src = String(evt.target.result)
  })
  FR.readAsDataURL(files[0])
}
