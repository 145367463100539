import React from "react";

import './drawer.css'

export function toggleDrawer(id, width, evt = null) {
    if (evt) {
        evt.stopPropagation();
    }

    const e = document.getElementById(`canvas-drawer-${id}`);
    const d = document.getElementById(`drawer-${id}`);
    if (e && d) {
        if (e.classList.contains('drawer-open')) {
            e.classList.remove('drawer-open')
            d.style.maxWidth = null
        }
        else {
            e.classList.add('drawer-open')
            if (width) {
                d.style.width = width
                d.style.maxWidth = '100%'
            } else {
                d.style.width = '30%'
            }

        }
    }
}

export const DrawerButton = props => {
    let width = 'auto'
    if (props.width) {
        width = props.width
    }
    return (
        <span onClick={(e) => { toggleDrawer(props.id, width, e); }} className={props.className}>{props.children}</span>
    )
}

export const Drawer = props => {
    let orientation = "from-right"
    if (props.left) {
        orientation = "from-left"
    }
    let width = 'auto'
    if (props.width) {
        width = props.width
    }
    return (
        <span>
            <div id={`canvas-drawer-${props.id}`} className={`drawer-container`} onClick={(e) => { toggleDrawer(props.id, width, e) }}></div>
            <div id={`drawer-${props.id}`}
                className={`drawer ${orientation}`}
                onClick={(e) => { e.stopPropagation() }}>
                {props.children}
            </div>
        </span>
    )
}