import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom'
import { apiURL } from '../constants/constants'
import PostsResults from './social-media/PostsResults'
import UserPage from './social-media/UserPage'
import SearchResults from './social-media/SearchResults'
import Navbar from './Navbar'
import { Container } from '@material-ui/core'
import axios from 'axios'
import UserContext from '../context/UserContext'
import Login from './auth/Login'
import Register from './auth/Register'

import './styles/style.css'
import { NewCanvas } from './pixel-editor/drawer/NewCanvas'
import { EditCanvas } from './pixel-editor/drawer/EditCanvas'
import { DialogWrapper } from './elements/Dialog'

const ApplicationContainer = () => {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  })

  useEffect(() => {
    const checkLoggedIn = async () => {
      // eslint-disable-next-line no-undef
      let token = localStorage.getItem('auth-token')
      if (token === null) {
        // eslint-disable-next-line no-undef
        localStorage.setItem('auth-token', '')
        token = ''
      }
      const tokenRes = await axios.post(
        `${apiURL}/api/users/token-is-valid`,
        null,
        { headers: { 'x-auth-token': token } })

      if (tokenRes.data) {
        const userRes = await axios.get(`${apiURL}/api/users/`,
          { headers: { 'x-auth-token': token } })
        setUserData({
          token,
          user: userRes.data
        })
      }
    }

    checkLoggedIn()
  }, [])

  return (
    <Router>
      <UserContext.Provider value={{ userData, setUserData }}>
        <Navbar />
        <Switch>
          <Route exact path='/' component={NewCanvas} />
          <Route exact path='/posts' component={PostsResults} />
          <Route exact path='/posts/:id' component={UserPage} />
          <Route exact path='/posts/edit/:id/:size?' component={EditCanvas} />
          <Route exact path='/search/:id' component={SearchResults} />
          <Route path='/login' exact component={Login} />
          <Route path='/register' exact component={Register} />
          <Route>
            <Container style={{ backgroundColor: 'inherit' }}>
              <Typography variant='h2' color='textPrimary' component='p' align='center'>
                404 Page Not Found
              </Typography>
              <Link to='/' style={{ textDecoration: 'none', color: 'rgba(2,125,88,1)', textAlign: 'center' }}><Typography variant='h3'>Go Create something cool instead...</Typography></Link>
            </Container>
          </Route>
        </Switch>
        {/* <DialogWrapper /> */}
        {/* <div>Icons made by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
      </UserContext.Provider>
    </Router>

  )
}

export default ApplicationContainer
