import axios from 'axios'
import paper from 'paper'
import { apiURL } from '../../../constants/constants'


export const saveImage = (title, description, privatePost, setRec, setPi) => {
  const token = localStorage.getItem('auth-token')
  const base = paper.project.layers[0].rasterize(100, false)
  base.width = 16
  base.height = 16

  base.onLoad = () => {
    const canvas = document.createElement('canvas')
    canvas.width = base.width
    canvas.height = base.height
    const context = canvas.getContext('2d')
    context.drawImage(base.canvas, 0, 0)

    if (paper.project.layers.length === 2 && canvas) {
      let options = {
        "Body": canvas.toDataURL('image/png'),
        "privatePost": privatePost,
        "title": title,
        "description": description
      }

      const header = {
        'x-auth-token': token,
        'Accept': 'application/json',
        'Content-type': 'application/json',
      }

      axios.post(`${apiURL}/api/posts/new`, options, { headers: header })
        .then(res => { setRec(res.data.msg); setPi(res.data.post) })
        .catch(async (e) => {
          if (e.response) {
            const msg = e.response.data.msg
            setRec(`Something went wrong. ${msg}`)
          } else {
            setRec("Something went wrong.")
          }
        })
    }
    // else {
    //   for (let i = 1; i < (paper.project.layers.length - 1); i++) {
    //     const raster = paper.project.layers[i].rasterize(100, false)
    //     raster.width = canvas.width
    //     raster.height = canvas.height
    //     raster.onLoad = () => {
    //       context.drawImage(raster.canvas, 0, 0)
    //       if (i === paper.project.layers.length - 2) {
    //         craftRequest(title, description, privatePost, canvas, token)
    //       }
    //     }
    //   }
    // }
  }
}

export const editImage = (title, description, privatePost, postID, setRec) => {
  const token = localStorage.getItem('auth-token')
  const base = paper.project.layers[0].rasterize(100, false)
  base.width = 16
  base.height = 16

  base.onLoad = () => {
    const canvas = document.createElement('canvas')
    canvas.width = base.width
    canvas.height = base.height
    const context = canvas.getContext('2d')
    context.drawImage(base.canvas, 0, 0)

    if (paper.project.layers.length === 2 && canvas) {
      let options = {
        "Body": canvas.toDataURL('image/png'),
        "privatePost": privatePost,
        "title": title,
        "description": description
      }

      const header = {
        'x-auth-token': token,
        'Accept': 'application/json',
        'Content-type': 'application/json',
      }
      axios.post(`${apiURL}/api/posts/edit/${postID}`, options, { headers: header })
        .then(res => { setRec(res.data.msg) })
        .catch(e => {
          if (e.response) {
            const msg = e.response.data.msg
            setRec(`Something went wrong. ${msg}`)
          } else {
            setRec("Something went wrong.")
          }

        })

    }
  }
}
