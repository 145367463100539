import React, { useState } from 'react'
import { TextField } from './TextField'
import { TextArea } from './TextArea'

import './styles/Dialog.css'

// export const Dialog = () => {

//     const style = {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'flex-start',
//         margin: '1rem',
//     }

//     const [title, setTitle] = useState('')
//     const [desc, setDesc] = useState('')

//     const handleSubmit = (e) => {
//         e.preventDefault()
//         console.log({ title, desc })
//         setTitle('')
//         setDesc('')
//     }

//     return (
//         <section style={style}>
//             <form onSubmit={handleSubmit}>
//                 <TextField
//                     name="title"
//                     label="Title (Max 50 characters):"
//                     required
//                     maxLength="50"
//                     size="30"
//                     value={title}
//                     setValue={setTitle}
//                     placeholder="Enter a title"
//                 />
//                 <TextArea
//                     name="description"
//                     label="Description (Max 800 characters):"
//                     required
//                     maxLength="800"
//                     value={desc}
//                     setValue={setDesc}
//                     placeholder="Enter a description"
//                 />
//                 <div><button type="submit">Submit</button></div>

//             </form>
//         </section>
//     )
// }


export function toggleDialog(id, evt = null) {
    if (evt) {
        evt.stopPropagation();
    }

    const e = document.getElementById(`dialog-container-${id}`);
    const d = document.getElementById(`dialog-${id}`);
    if (e && d) {
        if (e.classList.contains('dialog-open')) {
            e.classList.remove('dialog-open')
            d.classList.remove('dialog-open')
        }
        else {
            e.classList.add('dialog-open')
            d.classList.add('dialog-open')
        }
    }
}

export const DialogWrapper = () => {

    const style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '1rem',
    }

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log({ title, desc })
        setTitle('')
        setDesc('')
    }

    return (
        <div>
            <DialogButton id="1"><button>Click ME</button></DialogButton>
            <Dialog id="1">
                <section style={style}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            name="title"
                            label="Title (Max 50 characters):"
                            required
                            maxLength="50"
                            size="30"
                            value={title}
                            setValue={setTitle}
                            placeholder="Enter a title"
                        />
                        <TextArea
                            name="description"
                            label="Description (Max 800 characters):"
                            required
                            maxLength="800"
                            value={desc}
                            setValue={setDesc}
                            placeholder="Enter a description"
                        />
                        <div><button type="submit">Submit</button></div>

                    </form>
                </section>
            </Dialog>
        </div>
    )
}

export const DialogButton = props => {
    return (
        <span onClick={(e) => { toggleDialog(props.id, e); }} className={props.className}>{props.children}</span>
    )
}

export const Dialog = props => {

    return (
        <span>
            <div id={`dialog-container-${props.id}`} className={`dialog-container`} onClick={(e) => { toggleDialog(props.id, e) }}></div>
            <div id={`dialog-${props.id}`}
                className={`dialog-content `}
                onClick={(e) => { e.stopPropagation() }}>
                {props.children}
            </div>
        </span>
    )
}