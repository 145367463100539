import { Dialog, DialogTitle } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import React, { useContext, useState } from 'react'
import UserContext from '../context/UserContext'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
// import Drawer from '@material-ui/core/Drawer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone'
import ExploreTwoToneIcon from '@material-ui/icons/ExploreTwoTone'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook'
import SearchField from './auth/SearchField'
import Login from './auth/Login'
import Register from './auth/Register'
import { Drawer, DrawerButton, toggleDrawer } from './pixel-editor/drawer/Drawer'

const useStyles = makeStyles((theme) => ({
  drawerLink: {
    color: 'black',
    textDecoration: 'none',
    textTransform: 'uppercase'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }
}))

export const Navbar = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { userData, setUserData } = useContext(UserContext)
  const [loginOpen, setLoginOpen] = useState(false)
  const [registerOpen, setRegisterOpen] = useState(false)

  const handleLoginOpen = () => {
    setLoginOpen(true)
  }

  const handleLoginClose = () => {
    setLoginOpen(false)
  }

  const handleRegisterOpen = () => {
    setRegisterOpen(true)
  }

  const handleRegisterClose = () => {
    setRegisterOpen(false)
  }

  const logout = () => {
    setUserData({
      token: undefined,
      user: undefined
    })
    // eslint-disable-next-line no-undef
    localStorage.setItem('auth-token', '')
    toggleDrawer('nav-drawer')
  }

  return (
    <div>
      <header className="main-nav">
        <section className="logo">
          <h1 className="ag-title">art GRDN.</h1>
        </section>
        <section className="nav-links">
          <SearchField />
          <DrawerButton id="nav-drawer" width="240px">
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
            >
              <MenuIcon />
            </IconButton>
          </DrawerButton>

        </section>
      </header>
      <Drawer id="nav-drawer" width="240px" left>
        <div className={classes.drawerHeader}>
          <DrawerButton id="nav-drawer" width="240px">
            <IconButton>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerButton>
        </div>
        {userData.user
          ? (
            <>
              <Divider />
              <List>
                <ListItem button component='a' href={`/posts/${userData.user.id}`}>
                  <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                  <ListItemText className={classes.drawerLink} primary={`${userData.user.displayName}`} />
                </ListItem>
              </List>
            </>
          ) : null}
        <Divider />
        <List>
          <ListItem button component='a' href='/'>
            <ListItemIcon><CreateTwoToneIcon /></ListItemIcon>
            <ListItemText className={classes.drawerLink} primary='Create' />
          </ListItem>
          <ListItem button component='a' href='/posts'>
            <ListItemIcon><ExploreTwoToneIcon /></ListItemIcon>
            <ListItemText className={classes.drawerLink} primary='Discover' />
          </ListItem>
        </List>
        <Divider />
        <List>
          {
            userData.user
              ? (
                <ListItem button onClick={logout}>
                  <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                  <ListItemText className={classes.drawerLink} primary='Log Out' />
                </ListItem>
              )
              : (
                <>
                  <ListItem button onClick={handleRegisterOpen}>
                    <ListItemIcon><LaptopChromebookIcon /></ListItemIcon>
                    <ListItemText className={classes.drawerLink} primary='Register' />
                  </ListItem>
                  <ListItem button onClick={handleLoginOpen}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText className={classes.drawerLink} primary='Log In' />
                  </ListItem>
                </>
              )
          }
        </List>
      </Drawer>
      <Dialog onClose={handleLoginClose} aria-labelledby='login' open={loginOpen} fullWidth>
        <DialogTitle id='login'>Log In</DialogTitle>
        <Login handleLoginClose={handleLoginClose} />
      </Dialog>
      <Dialog onClose={handleRegisterClose} aria-labelledby='register' open={registerOpen} fullWidth>
        <DialogTitle id='register'>Register</DialogTitle>
        <Register handleRegisterClose={handleRegisterClose} />
      </Dialog>
    </div>
  )
}

export default Navbar
