import { Button, Dialog, DialogTitle, Grid, List, ListItem } from '@material-ui/core'
// import Typography from '@material-ui/core/Typography'
import React from 'react'
import { clearCanvas } from './utils/InputUtils'
// import { changeColor } from './utils/DrawingUtil'
// import IconButton from '@material-ui/core/IconButton'
// import UndoSharpIcon from '@material-ui/icons/UndoSharp'
// import RedoSharpIcon from '@material-ui/icons/RedoSharp'
// import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined'
// import ColorizeOutlinedIcon from '@material-ui/icons/ColorizeOutlined'
// import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import { IconButton as IB } from './drawer/IconButton'

export const setSelectedToolLabel = (value) => {
  document.getElementById('selected-tool-id').innerHTML = `Tool: <span id='selected-tool-text'> ${value}  </span>`
}

/**
 * Represents the sliders that control opacity, pan and zoom
 * @constructor
 * @param {object} props Component props
 * @param {function} props.setOpacity - function that sets the opacity variable (transparency of draw)
 * @param {function} props.setColorSelect - function that set the active color
 * @param {function} props.getColorSelect - function that gets the active color
 * */
// export const Sliders = ({ setOpacity, setColorSelect, getColorSelect }) => {
//   return (
//     <span>
//       <div>
//         <Typography>
//           Transparency
//         </Typography>
//         <Slider
//           defaultValue={1.0}
//           min={0.05}
//           max={1.0}
//           step={0.05}
//           onChange={(e, val) => {
//             setOpacity(val)
//             changeColor(getColorSelect(), setColorSelect, val)
//           }}
//           id='opacity-slider'
//           aria-labelledby='opacity-slider'
//           marks
//           valueLabelDisplay='auto'
//         />
//       </div>
//     </span>)
// }

export const ClearDialog = ({ tools, dimensions }) => {
  const [open, setOpen] = React.useState(false)
  const string = 'Are you sure you want to clear the canvas? Clearing the canvas cannot be undone.'

  const buttonText = 'Clear It'

  const handleClickOpen = () => {
    setOpen(true)
    tools.empty.activate()
    setSelectedToolLabel('None')
  }

  const handleClose = () => {
    tools.draw.activate()
    setOpen(false)
    setSelectedToolLabel('Draw')
  }

  const handleSubmit = () => {
    clearCanvas(dimensions)
    handleClose()
  }

  return (
    <span>
      <IB icon="clear" label="Clear" onClick={handleClickOpen} title="Clear the canvas" />
      <Dialog onClose={handleClose} aria-labelledby='clear-dialog' open={open} fullWidth>
        <DialogTitle id='clear-dialog'>{string}</DialogTitle>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
        >
          <Grid item>
            <List>
              <ListItem>
                <Button variant='contained' color='secondary' size='small' onClick={handleSubmit}>{buttonText}</Button>
                <Button variant='contained' color='primary' size='small' onClick={handleClose}>Cancel</Button>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Dialog>
    </span>
  )
}

// export const EditorButtons = ({ tools, redoList, dimensions }) => {
//   return (
//     <Container id="editor-buttons">
//       <IconButton onClick={() => {
//         tools.draw.activate()
//         setSelectedToolLabel('Draw')
//       }}
//       >
//         <CreateOutlinedIcon />
//       </IconButton>
//       <IconButton onClick={() => {
//         tools.flood.activate()
//         setSelectedToolLabel('Fill')
//       }}
//       >
//         <FormatColorFillOutlinedIcon />
//       </IconButton>
//       <IconButton onClick={() => {
//         tools.picker.activate()
//         setSelectedToolLabel('Color Picker')
//       }}
//       >
//         <ColorizeOutlinedIcon />
//       </IconButton>
//       <Button onClick={() => {
//         tools.mirror.activate()
//         setSelectedToolLabel('Mirror Draw')
//       }}
//       >Mirror
//       </Button>
//       <Button onClick={() => {
//         tools.erase.activate()
//         setSelectedToolLabel('Eraser')
//       }}
//       >Erase
//       </Button>
//       <ClearDialog tools={tools} dimensions={dimensions} />
//       <IconButton onClick={(event) => undoAction(event, redoList)}><UndoSharpIcon /></IconButton>
//       <IconButton onClick={(event) => redoAction(event, redoList)}><RedoSharpIcon /></IconButton>
//       <Button onClick={() => toggleGrid()}>Toggle Grid</Button>
//     </Container>
//   )
// }
