import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { apiURL } from '../../constants/constants'
import './../styles/card.css'
import { ReactComponent as Settings } from '../../images/settings.svg'
import { ReactComponent as Lock } from '../../images/padlock.svg'
import { DelDialog } from '../pixel-editor/DelDialog'

const formatDate = (post) => {
  let date
  let s = ''

  if (post.updatedAt === post.createdAt) {
    date = post.createdAt
  } else {
    date = post.updatedAt
    s = "Edited "
  }

  const daysOld = moment().diff(date.slice(0, 10), 'days')
  switch (daysOld) {
    case 0:
      return `${s}Today`
    case 1:
      return `${s}1 Day Ago`
    default:
      return `${s}${daysOld} Days Ago`
  }
}


export const Post = ({ post, isUserPage, isUser }) => {
  const history = useHistory()
  const thisDescription = useRef(null)
  const thisOverflow = useRef(null)
  const [over, setOver] = useState(false)
  const [style, setStyle] = useState({})

  const deletePost = async () => {
    const token = localStorage.getItem('auth-token')
    const header = {
      'x-auth-token': token,
    }

    return axios.delete(`${apiURL}/api/posts/${post._id}`, { headers: header })
      .then(res => { console.log(res.data); setStyle({ display: 'none' }); return 'Delete Successful' })
      .catch(e => console.log(e))
  }

  const editPost = async () => {
    history.push(`/posts/edit/${post._id}`)
  }

  useEffect(() => {
    if (post.description) {
      if (!over) {
        const descs = document.getElementsByClassName('description');
        if (descs) {
          const d = descs[0]
          const w = d.offsetWidth
          const chars = w / 7.0
          const lines = post.description.length / chars
          if (lines >= 2.5) {
            setOver(true)
          }
        }
      } else {
        thisOverflow.current.innerText = 'More...'
      }
    }

  }, [over, post.description])

  return (
    <article className="card" key={post._id} style={style}>
      {isUserPage ?
        <header className="header">
          <div className="content">
            <p className="title-user"> {post.privatePost ? <Lock className="privatepost" /> : null}{post.title} </p>
          </div>
          {isUser ?
            <div className="action">
              <div className="dropdown">
                <Settings title="Options Menu" />
                <ul className="dropdown-content">
                  <li onClick={editPost}>Edit</li>
                  <DelDialog deletePost={deletePost} />
                </ul>
              </div>
            </div> : null}
        </header>
        :
        <header className="header">
          <div className="content">
            <p className="title-user">{post.title}</p>
          </div>
          <div className="action" onClick={() => history.push(`/posts/${post.uid}`)}>
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" className="profilesvg">
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z">
              </path>
            </svg>
            <p className="author">{post.username}</p>
          </div>
        </header>}

      <section className="image-section">
        <img src={post.url}
          alt={`${post.title} by ${post.username}`}
          title={`${post.title} by ${post.username}`}
          aria-label={`${post.title} by ${post.username}`} />
      </section>
      <footer className="footer">
        <p className="time">{formatDate(post)}</p>
        <p className="description" ref={thisDescription}>{post.description}</p>
        {over ? <span className='more-less-btn' ref={thisOverflow} onClick={() => {
          if (thisDescription.current.style.maxHeight === 'none') {
            thisOverflow.current.innerText = 'More...'
            thisDescription.current.style.maxHeight = '2.25rem'
          } else {
            thisDescription.current.style.maxHeight = 'none';
            thisOverflow.current.innerText = 'Less...'
          }

        }} /> : null}
      </footer>
    </article>
  )
}
