import { Dialog, DialogTitle, List, ListItem, Button, } from '@material-ui/core'
import React from 'react'
import { TextField } from '../elements/TextField'


export const DelDialog = ({ deletePost }) => {
  const [open, setOpen] = React.useState(false)
  const [prompt, setPrompt] = React.useState('')
  const [rec, setRec] = React.useState('')
  const [err, setErr] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setRec('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // handleClose()
    if (prompt === 'delete') {
      console.log('deleted')
      const r = await deletePost()
      setRec(r)
    } else {
      setErr("Failed to delete. Type 'delete' in the box below")
    }
  }

  return (
    <div>
      <li onClick={handleClickOpen} title="Delete Post">Delete</li>
      <Dialog onClose={handleClose} aria-labelledby='delete-dialog' open={open} fullWidth>
        <DialogTitle id='delete-dialog'>Delete Post</DialogTitle>
        {rec ? <p className="save-dialog-login">{rec}</p> :
          <form onSubmit={handleSubmit}>
            <List>
              {err ? <ListItem>
                {err}
              </ListItem> : null}

              <ListItem>
                <TextField
                  name="delete"
                  label="Type 'delete'"
                  required
                  maxLength="6"
                  size="20"
                  value={prompt}
                  setValue={setPrompt}
                  placeholder="delete"
                />
              </ListItem>
              <ListItem>
                <Button variant='contained' color='primary' size='small' type='submit'>Delete It</Button>
              </ListItem>
            </List>
          </form>}
      </Dialog>
    </div>
  )
}
