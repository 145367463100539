import React, { useState, useContext } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { apiURL } from '../../constants/constants'
import UserContext from '../../context/UserContext'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ErrorNotice from './ErrorNotice'
import axios from 'axios'
import { toggleDrawer } from '../pixel-editor/drawer/Drawer'

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      Art GRDN
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

export default function Register({ handleRegisterClose }) {
  const classes = useStyles()
  const [password, setPassword] = useState()
  const [passwordCheck, setPasswordCheck] = useState()
  const [displayName, setDisplayName] = useState()
  const [error, setError] = useState('')

  const { setUserData } = useContext(UserContext)

  const submit = async (e) => {
    try {
      e.preventDefault() // Stops page reload
      const newUser = { password, passwordCheck, displayName }
      await axios.post(`${apiURL}/api/users/register`, newUser)
      const loginRes = await axios.post(`${apiURL}/api/users/login`, { displayName, password })
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user
      })
      // eslint-disable-next-line no-undef
      localStorage.setItem('auth-token', loginRes.data.token)
      handleRegisterClose()
      toggleDrawer('nav-drawer')
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg)
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      {error && <ErrorNotice message={error} clearError={() => setError('')} />}
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign Up
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='display-name'
            label='Display Name'
            name='display-name'
            autoFocus
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password-check'
            label='Password Check'
            type='password'
            id='password-check'
            onChange={(e) => setPasswordCheck(e.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Register
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
