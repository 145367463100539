import axios from 'axios'
import React, { Component } from 'react'
import { apiURL } from '../../../constants/constants'
import { NewCanvas } from './NewCanvas'

export class EditCanvas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            postItem: null,
            error: null
        }
    }

    async userRequest(user) {
        const token = localStorage.getItem('auth-token')
        await axios.get(`${apiURL}/api/posts/get/${user}`, { headers: { 'x-auth-token': token } })
            .then(res => {
                const r = res.data
                r['sizeparam'] = this.props.match.params.size
                this.setState({ postItem: r })
            })
            .catch(e => {
                if (e.response) {
                    const msg = e.response.data.msg
                    this.setState({ error: `Something went wrong. ${msg}` })
                } else {
                    this.setState({ error: "Something went wrong." })
                }
            })
    }

    async componentDidMount() {
        await this.userRequest(this.props.match.params.id)
    }

    async componentDidUpdate(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            await this.userRequest(nextProps.match.params.id)
        }
    }

    render() {
        return (
            <>
                {this.state.postItem ?
                    <span>
                        <NewCanvas postItem={this.state.postItem} />
                    </span> : this.state.error}
            </>
        )
    }
}
