import React from 'react'
import './styles/TextField.css'

/*<TextField
    name = "title"
    label = "Title (Max 50 characters):"
    required
    maxLength = "50"
    size = "30"
    value = { title }
    setValue = { setTitle }
    placeholder = "Enter a title"
/> */
export const TextField = ({ label, placeholder, maxLength, size, name, value, setValue, required = false }) => {

    return (
        <span className="text-field-wrapper">
            <label className="field-label" htmlFor={name}>{label}{required ? <span className="asterisk"> *</span> : null}</label>
            <input type="text" className="field-input" id={name} name={name} value={value} onChange={(e) => setValue(e.target.value)} required={required} maxLength={maxLength} size={size} placeholder={placeholder} />
        </span>
    )
}