import axios from 'axios'
import React, { Component } from 'react'
import { apiURL } from '../../constants/constants'
import PictureFeed from './PictureFeed'

export default class PostsResults extends Component {
  constructor (props) {
    super(props)

    this.state = { posts: [] }
  }

  componentDidMount () {
    axios.get(`${apiURL}/api/posts/`)
      .then(res => {
        this.setState({ posts: res.data })
      })
      .catch(error => {
        console.log(error)
      })
  }

  render () {
    return (
      <div>
        {this.state.posts.length > 0 ? <PictureFeed posts={this.state.posts} styleName='normal-post' /> : null}
      </div>
    )
  }
}
