import axios from 'axios'
import React, { Component } from 'react'
import { apiURL } from '../../constants/constants'
import PictureFeed from './PictureFeed'

export default class UserPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      posts: [],
      displayName: '',
      isUser: false
    }
  }

  async userRequest(user) {
    // eslint-disable-next-line no-undef
    const token = await localStorage.getItem('auth-token')
    await axios.get(`${apiURL}/api/posts/${user}`, { headers: { 'x-auth-token': token } })
      .then(res => {
        this.setState({ displayName: res.data.displayName })
        this.setState({ posts: res.data.posts })
        this.setState({ isUser: res.data.isUser })
      })
      .catch(error => {
        console.log(error)
      })
  }

  async componentDidMount() {
    await this.userRequest(this.props.match.params.id)
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      await this.userRequest(nextProps.match.params.id)
    }
  }

  render() {
    return (
      <>
        <header className="user-header">
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" className="profilesvg">
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z">
            </path>
          </svg>
          <h2 className="username-h2">{this.state.displayName}</h2>
        </header>
        <div>
          {this.state.posts.length > 0 ? <PictureFeed posts={this.state.posts} isUserPage={true} isUser={this.state.isUser} /> : null}
        </div>
      </>
    )
  }
}
