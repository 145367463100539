import { Dialog, DialogTitle, List, ListItem, Button, Grid, FormControlLabel } from '@material-ui/core'
import React from 'react'
import { saveImage, editImage } from './utils/Save'
import paper from 'paper'
import Checkbox from '@material-ui/core/Checkbox'
import { setSelectedToolLabel } from './PixelButtons'
import { IconButton } from './drawer/IconButton'
import UserContext from '../../context/UserContext'

import { TextField } from '../elements/TextField'
import { TextArea } from '../elements/TextArea'

export const placeImage = () => {
  const base = paper.project.layers[0].rasterize(100, false)
  base.width = 320
  base.height = 320
  base.onLoad = () => {
    const canvas = document.createElement('canvas')
    canvas.width = base.width
    canvas.height = base.height
    const context = canvas.getContext('2d')
    context.drawImage(base.canvas, 0, 0)

    if (paper.project.layers.length === 2 && canvas) {
      document.getElementById('post-dialog-image').appendChild(canvas)
    }
    // else {
    //   for (let i = 1; i < (paper.project.layers.length - 1); i++) {
    //     const raster = paper.project.layers[i].rasterize(90, false)
    //     raster.width = canvas.width
    //     raster.height = canvas.height
    //     raster.onLoad = () => {
    //       context.drawImage(raster.canvas, 0, 0)
    //       if (i === paper.project.layers.length - 2) {
    //         document.getElementById('post-dialog-image').appendChild(canvas)
    //       }
    //     }
    //   }
    // }
  }
}

const saveDialogStyle = {
  margin: "4px 0"
}

export const SaveDialog = ({ tools, setPi, postItem = null }) => {
  const [open, setOpen] = React.useState(false)
  const [priv, setPriv] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [desc, setDesc] = React.useState('')
  const [rec, setRec] = React.useState('')
  const { userData } = React.useContext(UserContext)

  const handleClickOpen = () => {
    if (postItem()) {
      setPriv(postItem().privatePost)
      setTitle(postItem().title)
      setDesc(postItem().description)
    }
    setOpen(true)
    tools.empty.activate()
    setSelectedToolLabel('None')
    if (userData.user && !rec) {
      placeImage()
    }

  }

  const handleClose = () => {
    setRec('')
    setOpen(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // handleClose()
    if (postItem()) {
      editImage(title, desc, priv, postItem()._id, setRec)
    } else {
      saveImage(title, desc, priv, setRec, setPi)
    }

  }

  return (
    <div>
      <IconButton icon="floppydisk" noClass onClick={handleClickOpen} style={saveDialogStyle} title="Save/Post Canvas Image" />
      <Dialog onClose={handleClose} aria-labelledby='save-dialog' open={open} fullWidth>
        <DialogTitle id='save-dialog'>{postItem() ? "Edit" : "Post"} Image</DialogTitle>

        {userData.user && !rec ?
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
          >
            <Grid item>
              <form onSubmit={handleSubmit}>
                <List>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={priv}
                          onChange={(e) => setPriv(e.target.checked)}
                          name='private-post'
                          color='primary'
                        />
                      }
                      label='Private'
                    />
                    {/* <input type="checkbox" checked={priv} onChange={(e) => setPriv(e.target.checked)} /> */}
                  </ListItem>
                  <ListItem>
                    <TextField
                      name="title"
                      label="Title (Max 50 characters):"
                      required
                      maxLength="50"
                      size="30"
                      value={title}
                      setValue={setTitle}
                      placeholder="Enter a title"
                    />
                  </ListItem>
                  <ListItem>
                    <TextArea
                      name="description"
                      label="Description (Max 800 characters):"
                      required
                      maxLength="800"
                      value={desc}
                      setValue={setDesc}
                      placeholder="Enter a description"
                    />
                  </ListItem>
                  <ListItem>
                    <Button variant='contained' color='primary' size='small' type='submit'>{postItem() ? "Edit" : "Post"} It</Button>
                  </ListItem>
                </List>
              </form>
            </Grid>
            <Grid item id='post-dialog-image' />
          </Grid>
          : rec ? <p className="save-dialog-login">{rec}</p> : <p className="save-dialog-login">Login/Register to Post</p>}
      </Dialog>
    </div>
  )
}
