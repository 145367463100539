import { ClearDialog, setSelectedToolLabel } from '../PixelButtons'
import { redoAction, toggleGrid, undoAction, } from '../utils/InputUtils'
import {
    changeColor,
    floodFill,
    useNoState,
    checkBounds,
    getPixelColor,
    drawGridLines,
    allowOnlyLeftClick
} from '../utils/DrawingUtil'
import paper, { Path, Point, Size, Tool, CompoundPath, Raster } from 'paper'
import React, { useEffect, useState } from 'react'
import { Drawer, DrawerButton } from './Drawer'
import { IconButton } from './IconButton'
import { SaveDialog } from '../SaveDialog'
import { ReactComponent as ColorWheel } from '../../../images/011-color-wheel.svg'

export const tools = {}
let gridLayer

const redoList = []

// eslint-disable-next-line react-hooks/rules-of-hooks
const [getOpacity] = useNoState('1.0')
// eslint-disable-next-line react-hooks/rules-of-hooks
const [getColorSelect, setColorSelect] = useNoState('#100000')
let dimensions = {}

export const NewCanvas = ({ postItem = null }) => {

    paper.install(window)
    const [loaded, setLoaded] = useState(false)
    const [getPi, setPi] = useNoState(postItem)

    useEffect(() => {
        const header = document.getElementsByClassName('main-nav')[0]
        let width
        let height
        let windowWidth = window.innerWidth - 20
        let windowHeight = window.innerHeight - header.clientHeight
        if ((windowHeight * 0.75) > windowWidth) {
            width = windowWidth
            height = windowWidth
        } else {
            width = windowHeight * 0.75
            height = windowHeight * 0.75
        }


        let sizePerRectangle = Math.floor(width / 16)

        if (postItem && postItem.sizeparam) {
            sizePerRectangle = Math.floor(width / postItem.sizeparam)
        }

        const canvasWidth = width - (width % sizePerRectangle)
        const canvasHeight = height - (height % sizePerRectangle)

        dimensions = { canvasWidth, canvasHeight, sizePerRectangle }

        const canvas = document.getElementById('main-canvas')
        canvas.width = dimensions.canvasWidth
        canvas.height = dimensions.canvasHeight
        const context = canvas.getContext('2d')
        paper.setup(canvas)

        const rectSize = new Size(dimensions.sizePerRectangle, dimensions.sizePerRectangle)
        const rectSizePoint = new Point(dimensions.sizePerRectangle, dimensions.sizePerRectangle)
        drawGridLines(rectSize, gridLayer, dimensions)

        const invisPoint = new Point(dimensions.canvasWidth - rectSize.width, dimensions.canvasWidth - rectSize.height)
        const invis = new Path.Rectangle(invisPoint, rectSize)
        invis.fillColor = 'black'
        invis.blendMode = 'destination-out'

        tools.draw = new Tool()
        tools.picker = new Tool()
        tools.mirror = new Tool()
        tools.flood = new Tool()
        tools.erase = new Tool()
        tools.empty = new Tool()

        let compound

        const onMouseDownRect = (event) => {
            if (checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                const newPoint = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)

                const old = getPixelColor(newPoint, context)

                if (old.color !== getColorSelect() || old.alpha === 0) {
                    const i = new Path.Rectangle(newPoint, rectSize)
                    i.fillColor = getColorSelect()
                    compound = new CompoundPath({
                        children: [i],
                        fillColor: getColorSelect(),
                        opacity: getOpacity()
                    })
                }
            }
        }
        const onMouseDragRect = (event) => {
            if (compound && checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                const newPoint = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)

                const old = getPixelColor(newPoint, context)

                if (old.color !== getColorSelect() || old.alpha === 0) {
                    const i = new Path.Rectangle(newPoint, rectSize)
                    i.fillColor = getColorSelect()
                    compound.addChild(i)
                }
            }
        }

        const onMouseDownMirror = (event) => {
            if (checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                const newPoint = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)
                const mirPoint = new Point(dimensions.canvasWidth - newPoint.x - dimensions.sizePerRectangle, newPoint.y)
                const old = getPixelColor(newPoint, context)
                const old2 = getPixelColor(newPoint, context)

                compound = new CompoundPath({
                    fillColor: getColorSelect(),
                    opacity: getOpacity()
                })

                if (old.color !== getColorSelect() || old.alpha === 0) {
                    const i = new Path.Rectangle(newPoint, rectSize)
                    i.fillColor = getColorSelect()
                    compound.addChild(i)
                }
                if (old2.color !== getColorSelect() || old2.alpha === 0) {
                    const i = new Path.Rectangle(mirPoint, rectSize)
                    i.fillColor = getColorSelect()
                    compound.addChild(i)
                }
                if (compound.children.length === 0) {
                    compound.remove()
                }
            }
        }
        const onMouseDragMirror = (event) => {
            if (compound && checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                const newPoint = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)
                const mirPoint = new Point(dimensions.canvasWidth - newPoint.x - dimensions.sizePerRectangle, newPoint.y)

                const old = getPixelColor(newPoint, context)
                const old2 = getPixelColor(newPoint, context)

                if (old.color !== getColorSelect() || old.alpha === 0) {
                    const i = new Path.Rectangle(newPoint, rectSize)
                    i.fillColor = getColorSelect()
                    compound.addChild(i)
                }
                if (old2.color !== getColorSelect() || old2.alpha === 0) {
                    const i = new Path.Rectangle(mirPoint, rectSize)
                    i.fillColor = getColorSelect()
                    compound.addChild(i)
                }
            }
        }

        const onColorPicker = (event) => {
            if (checkBounds(event, dimensions)) {
                const topleft = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)
                const old = getPixelColor(topleft, context)
                console.log(old.color, [topleft.x / 24, topleft.y / 24], topleft)
                if (old.color !== null) {
                    changeColor(old.color, setColorSelect, old.alpha)
                }

                tools.draw.activate()
                setSelectedToolLabel('Draw')
            }
        }

        const onKeyDownAll = (event) => {
            if (event.key === 'Control') {
                return
            }
            switch (event.key) {
                case 'd':
                    tools.draw.activate()
                    setSelectedToolLabel('Draw')
                    break
                case 'f':
                    tools.flood.activate()
                    setSelectedToolLabel('Fill')
                    break
                case 'm':
                    tools.mirror.activate()
                    setSelectedToolLabel('Mirror')
                    break
                case 'e':
                    tools.erase.activate()
                    setSelectedToolLabel('Eraser')
                    break
                case 'c':
                    tools.picker.activate()
                    setSelectedToolLabel('Extract')
                    break
                case 'space':
                    toggleGrid(event)
                    break
                case 'u':
                    undoAction(event, redoList)
                    break
                case 'j':
                    redoAction(event, redoList)
                    break
                default:
                    break
            }
        }

        let e_compound

        const eraseMouse = (event) => {
            if (checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                const topleft = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)
                const old = getPixelColor(topleft, context)
                e_compound = new CompoundPath({
                    fillColor: getColorSelect(),
                    blendMode: "destination-out",
                })
                if (old.color !== null) {
                    const i = new Path.Rectangle(topleft, rectSize)
                    i.blendMode = "destination-out"
                    i.fillColor = getColorSelect()
                    e_compound.addChild(i)
                }
            }
        }

        const eraseMouseDrag = (event) => {
            if (e_compound && checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                const topleft = new Point(Math.floor(event.point.x / rectSizePoint.x) * rectSizePoint.x, Math.floor(event.point.y / rectSizePoint.y) * rectSizePoint.y)
                const old = getPixelColor(topleft, context)
                if (old.color !== null) {
                    const i = new Path.Rectangle(topleft, rectSize)
                    i.blendMode = "destination-out"
                    i.fillColor = getColorSelect()
                    e_compound.addChild(i)
                }
            }
        }

        const fillMouseDown = (event) => {
            if (checkBounds(event, dimensions) && allowOnlyLeftClick(event)) {
                floodFill(event, rectSize, getColorSelect(), getOpacity(), dimensions.canvasWidth, dimensions.canvasHeight, dimensions.sizePerRectangle, context)
            }
            // updateImage()
        }

        tools.erase.onMouseDown = eraseMouse
        tools.erase.onMouseDrag = eraseMouseDrag
        tools.erase.onKeyDown = onKeyDownAll

        tools.picker.onMouseDown = onColorPicker
        tools.picker.onKeyDown = onKeyDownAll

        tools.draw.onMouseDown = onMouseDownRect
        tools.draw.onMouseDrag = onMouseDragRect
        tools.draw.onKeyDown = onKeyDownAll
        tools.draw.activate()

        tools.mirror.onMouseDown = onMouseDownMirror
        tools.mirror.onMouseDrag = onMouseDragMirror
        tools.mirror.onKeyDown = onKeyDownAll

        tools.flood.onMouseDown = fillMouseDown
        tools.flood.onKeyDown = onKeyDownAll

        if (postItem) {
            const raster = new Raster(
                {
                    crossOrigin: 'anonymous',
                    source: `${postItem.url}?origin=artgrdn.com`,
                    smoothing: false
                });

            raster.onLoad = () => {
                raster.position = paper.view.center
                const scale = dimensions.canvasWidth / raster.width
                raster.scale(scale)
            }
        }

        setLoaded(true)
    }, [postItem])

    return (
        <div className="main-canvas-section">
            {loaded ? <Drawer id="canvas-drawer" right>
                <div className="canvas-buttons">
                    <h2>Drawing Tools</h2>
                    <IconButton icon="edit" label="Draw" title="Drawing Tool" onClick={() => {
                        tools.draw.activate()
                        setSelectedToolLabel('Draw')
                    }} />
                    <IconButton icon="paintbucket" label="Fill" title="Bucket Fill Tool" onClick={() => {
                        tools.flood.activate()
                        setSelectedToolLabel('Fill')
                    }} />
                    <IconButton icon="colorpicker" label="Extract" title="Extract Color from Canvas Tool" onClick={() => {
                        tools.picker.activate()
                        setSelectedToolLabel('Extract')
                    }} />
                    <IconButton icon="flip" label="Mirror" title="Mirror Drawing (Y-Axis) Tool" onClick={() => {
                        tools.mirror.activate()
                        setSelectedToolLabel('Mirror')
                    }} />
                    <IconButton icon="eraser" label="Eraser" title="Eraser Tool" onClick={() => {
                        tools.erase.activate()
                        setSelectedToolLabel('Eraser')
                    }} />
                    <ClearDialog tools={tools} dimensions={dimensions} />
                </div>
            </Drawer> : null}
            <main>
                <section className="editor-wrapper">
                    {loaded ?
                        <section className="canvas-utility-bar">
                            <div className="color-picker-wrapper" title="Color Picker Tool">
                                <ColorWheel className="color-picker-icon" />
                                <input type="color" onChange={(e) => changeColor(e.target.value, setColorSelect, getOpacity())} />
                            </div>
                            <div className='color-tool-select-id'>
                                <span id='selected-tool-id'>
                                    Tool: <span id='selected-tool-text'> Draw  </span>
                                </span>
                            </div>
                            <SaveDialog tools={tools} postItem={getPi} setPi={setPi} />
                        </section>
                        : null}
                    <section className="canvas-main">
                        <canvas
                            id='main-canvas'
                            style={{ cursor: 'crosshair' }}
                        />
                    </section>
                    {loaded ? <section className="canvas-editor-buttons">
                        <DrawerButton id="canvas-drawer" className="is-drawer" >
                            <IconButton icon="tools" noClass title="Brings out the tools menu" />
                        </DrawerButton>
                        <IconButton icon="backarrow" noClass onClick={(event) => undoAction(event, redoList)} title="Undo Last Action" />
                        <IconButton icon="redoarrow" noClass onClick={(event) => redoAction(event, redoList)} title="Redo Last Action" />
                        <IconButton icon="grid" noClass onClick={() => toggleGrid()} title="Toggle Grid" />
                    </section> : null}
                </section>
                {loaded ? <div className="canvas-buttons no-drawer">
                    <h2>Drawing Tools</h2>
                    <IconButton icon="edit" label="Draw" title="Drawing Tool" onClick={() => {
                        tools.draw.activate()
                        setSelectedToolLabel('Draw')
                    }} />
                    <IconButton icon="paintbucket" label="Fill" title="Bucket Fill Tool" onClick={() => {
                        tools.flood.activate()
                        setSelectedToolLabel('Fill')
                    }} />
                    <IconButton icon="colorpicker" label="Extract" title="Extract Color from Canvas Tool" onClick={() => {
                        tools.picker.activate()
                        setSelectedToolLabel('Extract')
                    }} />
                    <IconButton icon="flip" label="Mirror" title="Mirror Drawing (Y-Axis) Tool" onClick={() => {
                        tools.mirror.activate()
                        setSelectedToolLabel('Mirror')
                    }} />
                    <IconButton icon="eraser" label="Eraser" title="Eraser Tool" onClick={() => {
                        tools.erase.activate()
                        setSelectedToolLabel('Eraser')
                    }} />
                    <ClearDialog tools={tools} dimensions={dimensions} />
                </div> : null}
            </main>
        </div>
    )
}