import React from 'react'
import { ReactComponent as Edit } from '../../../images/001-edit.svg'
import { ReactComponent as Eraser } from '../../../images/002-eraser.svg'
import { ReactComponent as Clear } from '../../../images/003-clear.svg'
import { ReactComponent as Flip } from '../../../images/004-flip.svg'
import { ReactComponent as ColorPicker } from '../../../images/005-color-picker.svg'
import { ReactComponent as PaintBucket } from '../../../images/006-paint-bucket.svg'
import { ReactComponent as BackArrow } from '../../../images/007-back-arrow.svg'
import { ReactComponent as RedoArrow } from '../../../images/008-redo-arrow.svg'
import { ReactComponent as Grid } from '../../../images/009-grid.svg'
import { ReactComponent as FloppyDisk } from '../../../images/010-floppy-disk.svg'
import { ReactComponent as ColorWheel } from '../../../images/011-color-wheel.svg'
import { ReactComponent as Tools } from '../../../images/012-tools.svg'


export const IconButton = (props) => {
    let iconSrc = []

    switch (props.icon) {
        case 'edit':
            iconSrc.push(<Edit className="icon-btn" key="icon" />);
            break;
        case 'eraser':
            iconSrc.push(<Eraser className="icon-btn" key="icon" />);
            break;
        case 'clear':
            iconSrc.push(<Clear className="icon-btn" key="icon" />);
            break;
        case 'flip':
            iconSrc.push(<Flip className="icon-btn" key="icon" />);
            break;
        case 'colorpicker':
            iconSrc.push(<ColorPicker className="icon-btn" key="icon" />);
            break;
        case 'paintbucket':
            iconSrc.push(<PaintBucket className="icon-btn" key="icon" />);
            break;
        case 'backarrow':
            iconSrc.push(<BackArrow className="icon-btn" key="icon" />);
            break;
        case 'redoarrow':
            iconSrc.push(<RedoArrow className="icon-btn" key="icon" />);
            break;
        case 'grid':
            iconSrc.push(<Grid className="icon-btn" key="icon" />);
            break;
        case 'floppydisk':
            iconSrc.push(<FloppyDisk className="icon-btn" key="icon" />);
            break;
        case 'colorwheel':
            iconSrc.push(<ColorWheel className="icon-btn" key="icon" />);
            break;
        case 'tools':
            iconSrc.push(<Tools className="icon-btn" key="icon" />);
            break;
        default:
            iconSrc = []
            break;
    }

    return (
        <div className={props.noClass ? null : 'icon-btn-wrapper'} onClick={props.onClick} style={props.style} title={props.title}>
            {iconSrc}
            {props.label ? <p className="icon-label">{props.label}</p> : null}
            {props.children}
        </div>
    )
}