import React from 'react'
import './styles/TextArea.css'

/* <TextArea
    name="description"
    label="Description (Max 800 characters):"
    required
    maxLength="800"
    value={desc}
    setValue={setDesc}
    placeholder="Enter a description"
/> */
export const TextArea = ({ label, placeholder, maxLength, name, value, setValue, rows = "5", cols = "32", required = false }) => {

    return (
        <span className="text-area-wrapper">
            <label className="area-label" htmlFor={name}>{label}{required ? <span className="asterisk"> *</span> : null}</label>
            <textarea className="area-input" id={name} name={name} rows={rows} cols={cols} value={value} onChange={(e) => setValue(e.target.value)} maxLength={maxLength} required={required} placeholder={placeholder}>
            </textarea>
        </span>
    )
}