import React from 'react'

export default function ErrorNotice (props) {
  return (
    <div className='error-notice'>
      <span>{props.message}</span>
      {/* eslint-disable-next-line react/jsx-handler-names */}
      <button onClick={props.clearError}>X</button>
    </div>
  )
}
