import paper, { Color, CompoundPath, Layer, Path, Point } from 'paper'
import { RGBToHex } from './ColorUtils'
import { toggleGrid } from './InputUtils'

export const getPixelColor = (point, context) => {
  const ratio = context.canvas.height / context.canvas.clientHeight // Adjusts for discrepancies in canvas coordinates
  const colorAtEvent = context.getImageData((point.x * ratio) + 2, (point.y * ratio) + 2, 1, 1)
  const oldColor = new Color(colorAtEvent.data)
  return { color: RGBToHex(oldColor.red, oldColor.green, oldColor.blue), alpha: (oldColor.alpha / 255) }
}

const arrayUniqueFill = (arr) => {
  return (arr.filter((ar, index, self) => self.findIndex(t => t.x === ar.x && t.y === ar.y) === index))
}

const addSpot = (loc, duplicateSet, queue, preLen) => {
  duplicateSet.add(loc.toString())
  if (preLen !== duplicateSet.size) {
    queue.push(loc)
    preLen += 1
  }
  return preLen
}

const getAdjacentPixels = (loc, size, context) => {
  const rightLocation = new Point(loc.x + size, loc.y)
  const rightColor = getPixelColor(rightLocation, context)
  const leftLocation = new Point(loc.x - size, loc.y)
  const leftColor = getPixelColor(leftLocation, context)
  const downLocation = new Point(loc.x, loc.y + size)
  const downColor = getPixelColor(downLocation, context)
  const upLocation = new Point(loc.x, loc.y - size)
  const upColor = getPixelColor(upLocation, context)

  return { rightLocation, rightColor, leftLocation, leftColor, downLocation, downColor, upLocation, upColor }
}

export const floodFill = (event, rectSize, colorSelect, opacity, width, height, size, context) => {
  let grid_vis = !toggleGrid()
  paper.project.layers[(paper.project.layers.length - 1)].visible = false
  if (paper.view.zoom <= 1.0) {
    const startPoint = new Point(Math.floor(event.point.x / size) * size, Math.floor(event.point.y / size) * size)
    const startcolor = getPixelColor(startPoint, context)

    if (startcolor.color === colorSelect && startcolor.alpha === Number(opacity)) {
      return
    }
    let q = []
    const set = new Set()

    const compound = new CompoundPath({
      children: [
        new Path.Rectangle(startPoint, rectSize)
      ],
      fillColor: colorSelect,
      opacity: opacity
    })

    q.push(startPoint)
    set.add(startPoint.toString())
    while (q.length > 0) {
      q = arrayUniqueFill(q)
      const loc = q.shift()
      let preLen = set.size

      const newPoint = new Point(loc.x, loc.y)
      compound.addChild(new Path.Rectangle(newPoint, rectSize))

      const adjacent = getAdjacentPixels(loc, size, context)

      if (adjacent.leftLocation.x >= 0 && adjacent.leftColor.color === startcolor.color && adjacent.leftColor.alpha === startcolor.alpha) {
        preLen = addSpot(adjacent.leftLocation, set, q, preLen)
      }
      if (adjacent.rightLocation.x < (width) && !(startPoint.x === width) && adjacent.rightColor.color === startcolor.color && adjacent.rightColor.alpha === startcolor.alpha) {
        preLen = addSpot(adjacent.rightLocation, set, q, preLen)
      }
      if (adjacent.downLocation.y < (height) && !(startPoint.x === height) && adjacent.downColor.color === startcolor.color && adjacent.downColor.alpha === startcolor.alpha) {
        preLen = addSpot(adjacent.downLocation, set, q, preLen)
      }
      if (adjacent.upLocation.y >= 0 && adjacent.upColor.color === startcolor.color && adjacent.upColor.alpha === startcolor.alpha) {
        preLen = addSpot(adjacent.upLocation, set, q, preLen)
      }
    }
    set.clear()
  }
  paper.project.layers[(paper.project.layers.length - 1)].visible = grid_vis
}

/**
 * Function to change the active color
 * @param {string} colorUpdate - the hex value of the new color
 * @param {function} setColorSelect - a function to set the active color
 * @param {number} opacity - the transparency of the color
 */
export const changeColor = (colorUpdate, setColorSelect, opacity) => {
  setColorSelect(colorUpdate)
  const cpw = document.getElementsByClassName('color-picker-wrapper')[0]
  cpw.style.backgroundColor = colorUpdate
  // cpw.style.opacity = opacity
  // const cs = document.getElementById('color-select-rect')
  // cs.setAttribute('fill', colorUpdate)
  // cs.setAttribute('fill-opacity', opacity)
}

/**
 * Function for making a variable that is only used through get and set functions that won't refresh components on change
 * @function useNoState
 * @param {string|number|boolean} initialValue - the default value of the no state variable
 * @return an array of get and set functions for a variable
 * @example [getVar, setVar] = useNoState('false')
 */
export const useNoState = (initialValue) => {
  let noState = initialValue
  const setNoState = (value) => { noState = value }
  const getNoState = () => noState

  return [getNoState, setNoState]
}

export const drawGridLines = (rectSize, gridLayer, dimensions) => {
  const zeroPoint = new Point(0, 0)
  const zero = new Path.Rectangle(zeroPoint, rectSize)
  zero.fillColor = 'black'
  zero.blendMode = 'destination-out'
  gridLayer = new Layer({
    children: [zero]
  })
  paper.project.insertLayer(0, gridLayer)

  for (let i = 0; i <= dimensions.canvasHeight; i += dimensions.sizePerRectangle) {
    const point1 = new Point(0, i)
    const point2 = new Point(dimensions.canvasWidth, i)
    const aLine = new Path.Line(point1, point2)
    aLine.strokeColor = 'gray'
    aLine.strokeWidth = 0.3
  }
  for (let i = 0; i <= dimensions.canvasWidth; i += dimensions.sizePerRectangle) {
    const point1 = new Point(i, 0)
    const point2 = new Point(i, dimensions.canvasHeight)
    const aLine = new Path.Line(point1, point2)
    aLine.strokeColor = 'gray'
    aLine.strokeWidth = 0.3
  }
  paper.project.layers[0].activate()
}

export const allowOnlyLeftClick = (event) => {
  return (event.event.button === 0 || event.event.button === undefined)
}
export const checkBounds = (event, dimensions) => {
  return (event.point.x >= 0 && event.point.y >= 0 && event.point.x < dimensions.canvasWidth && event.point.y < dimensions.canvasHeight)
}
