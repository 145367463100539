import React from 'react'
import { Post } from './Post'

const PictureFeed = ({ posts, isUserPage, isUser }) => {
  const postList = posts.map(post => {
    return (
      <Post post={post} isUserPage={isUserPage} key={post._id} isUser={isUser} />)
  })

  return (
    <main>
      {postList}
    </main>
  )
}

export default PictureFeed
