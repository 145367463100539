import axios from 'axios'
import React, { Component } from 'react'
import { apiURL } from '../../constants/constants'
import PictureFeed from './PictureFeed'

export default class SearchResults extends Component {
  constructor(props) {
    super(props)

    this.state = { posts: [] }

    this.searchRequest = this.searchRequest.bind(this)
  }

  async searchRequest(query) {
    await axios.post(`${apiURL}/api/posts/search`, { query: query })
      .then(res => {
        this.setState({ posts: res.data })
      })
      .catch(error => {
        console.log(error)
      })
  }

  async componentDidMount() {
    await this.searchRequest(this.props.match.params.id)
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      await this.searchRequest(this.props.match.params.id)
    }
  }

  render() {
    return (
      <div>
        {this.state.posts.length > 0 ? <PictureFeed posts={this.state.posts} styleName='normal-post' /> : null}
      </div>
    )
  }
}
