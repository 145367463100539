import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'

const SearchField = () => {
  const history = useHistory()
  let searchQuery = ''

  const searchOnChange = (e) => {
    searchQuery = e.target.value
  }

  const searchKeyDown = (e) => {
    if (e.key === 'Enter' && searchQuery) {
      history.push(`/search/${searchQuery}`)
    }
  }

  const search = async () => {
    if (searchQuery) {
      history.push(`/search/${searchQuery}`)
    }
  }

  return (
    <form>
      <input onChange={searchOnChange} onKeyDown={searchKeyDown} type="text" name="searchbar" id="searchbar" placeholder="Search" />
      <IconButton type='submit' size='medium' onClick={search} color="inherit">
        <SearchIcon />
      </IconButton>
    </form>
  )
}

export default SearchField
